<template>

	<div class="footer">
		<div class="footer-wrapper">
			<div class="container">
				<div class="footer-list">
					<section class="footer-item">
						<p translate="FOOTER.products" class="footer-item-title">
							PRODUCTS</p>
						<ul>
							<li><a class='link' href="/products/sms" translate="LINK.products-sms"
									routerlink="/products/sms">SMS
									API</a></li>
							<li><a class='link' href="/products/mms" translate="LINK.products-mms"
									routerlink="/products/mms">MMS
									API</a></li>
							<!---->
							<li><a class='link' href="/products/voice" translate="LINK.products-voice"
									routerlink="/products/voice">Voice API</a></li>
							<li><a class='link' href="/products/number-lookup" translate="LINK.products-api"
									routerlink="products/number-lookup">Phone Number Lookup API</a></li>
							<li><a class='link' href="/products/sending-tool" translate="LINK.products-tool"
									routerlink="products/sending-tool">SMS Sending Tool</a></li>
						</ul>
					</section>
					<section class="footer-item">
						<p translate="FOOTER.service" class="footer-item-title">
							DEVELOPERS</p>
						<ul>
							<li><a class="link" href="/docs/overview" routerlink="/docs/overview"
									translate="FOOTER.service-api">API
									Documentation</a>
							</li>
							<li><a class="link" href="/policy/terms-of-use" routerlink="/policy/terms-of-use"
									translate="FOOTER.service-terms">Terms of Use</a></li>
							<li><a class="link" href="/policy/terms-of-use" routerlink="/policy/terms-of-use"
									translate="FOOTER.service-service"></a></li>
							<li><a class="link" href="/policy/privacy/20220915" routerlink="/policy/privacy/20220915"
									translate="FOOTER.service-privacy">Privacy Policy</a></li>
							<li><a class="link" href="/faq" routerlink="/faq" translate="FOOTER.service-questions"></a>
							</li>
							<li><a class="link" href="/seo/sitemap" routerlink="/seo/sitemap"
									translate="FOOTER.service-sitemap">SiteMap</a></li>
							<li><a class="link" href="/information/company" routerlink="/information/company"
									translate="FOOTER.company"></a></li>
						</ul>
					</section>
					<!---->
					<section class="footer-item">
						<p translate="FOOTER.contact" class="footer-item-title">PHSMS</p>
						<!-- <ul>
							<li><span translate="FOOTER.contact-tel"></span></li>
							<li><span>
									<a class="text-underline" href="mailto:sales@qqloon.com">sales@qqloon.com</a>
									(Sales Team)
								</span></li>
							<li><span>
									<a class="text-underline" href="mailto:support@qqloon.com">support@qqloon.com</a>
									(Technical support
									and customer care)</span></li>
							<li><span translate="FOOTER.contact-qq"></span></li>
							<li><span>Bali Office: Sunset
									Road 819, Bali Ruko Sunset Indah II No. 10, Kuta, Kabupaten Badung, Bali 80361,
									Indonesia</span></li>
							<li><span>Dublin Office: Ground
									Floor, 71 Lower Baggot Street, Dublin, D02 P593, Eircode D02 P593,
									Ireland</span></li>
							<li><span>Hong Kong Office: Flat
									G, 17/F, King Palace Plaza, 55 King Yip St, Kwun Tong, Kowloon, Hong Kong
									SAR</span></li>
							<li><span>Mumbai Office: 1.27,
									1st Floor, 91Springboard, 74 Techno Park, Opp. Gate No 2, Seepz, Andheri East,
									Mumbai, Maharashtra 400093, India</span></li>
							<li><span>Paris Office: 4 Place
									Louis Armand, 75012 Paris, France</span></li>
							<li><span>Shenzhen Office：Suite
									1856, 18/F, Galaxy Center, 18 Zhongxin 5th Road, Futian District Shenzhen, PR
									China</span></li>
							<li><span>Taipei Office：Levels
									9-10, No. 156, Sec. 3, Minsheng E. Rd., Songshan Dist., Taipei City,
									Taiwan</span></li>
							<li><span>Thane Office: 601,
									Sixth floor, Spaces 912 BLDG, Pleasant Park, Mira Road, Mumbai, Maharashtra
									401107, India</span></li>
							<li><span>Tokyo Office: 4/F,
									1-14-14, Tomigaya, Shibuya District, Tokyo, Japan</span></li>
							<li><span>Xiamen Office: Unit
									1905, Ruihua Center, 1778 Lvling Road, Siming District, Xiamen, Fujian, PR
									China</span></li>
							<li class="cur-pointer">

								<a href="/information/contact-us" routerlink="/information/contact-us"
									translate="FOOTER.more" class="text-underline"></a>
							</li>
						</ul> -->
					</section>
				</div>
			</div>
		</div>
		<div class="footer-copyright">
			<div class="container text-center">
				<!---->
				<p class="footer-copyright-item"><small>©
						Copyright 2016-2023 qqloon.com All Rights Reserved</small> &nbsp; <small>
						<a href="/policy/cookies" routerlink="/policy/cookies" translate="FOOTER.cookie"
							class="text-primary">Cookies
							Policy</a></small> &nbsp; </p>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style lang="scss">
	.footer {
		width: 100%;

		&-wrapper {
			padding-top: 100px;
			background-color: #fafafa;
			border-bottom: 1px solid #ececec;
		}

		&-list {
			display: flex;
			flex-wrap: wrap;
		}

		&-item {
			flex: 1;
			margin-right: 20px;
			margin-bottom: 100px;

			&-title {
				margin-bottom: 28px;
				font-weight: 700;
				color: #8b8b8b;
			}

			ul li {
				margin-bottom: 10px
			}
		}

		&-item:last-child {
			flex: 3
		}

		.text-underline {
			text-decoration: underline;
			color: #131313;
		}

		.link {

			color: #131313;

			font-size: 14px;

			&:hover {
				color: #2a7de1;
				text-decoration: none;
			}
		}

		&-copyright {
			padding-bottom: 20px;
			background-color: #fafafa;

			.text-center {
				text-align: center;
			}

			&-item {
				padding-top: 20px;
				font-size: .8em;

			}
		}

		.text-primary {
			color: #2a7de1;
			text-decoration: none;

			&:hover {
				text-decoration: none;
				color: #2a7de1;
			}
		}


	}
</style>
