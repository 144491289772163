<template>
	<div class="cookieWrap" :class="show==true?'show':'none'">
		<div id="cookie-confirmation-inner">
			<div id="policy-wording">
				<p class="p">This website stores cookies on your computer. These cookies are used to collect information
					about how
					you
					interact with our website and allow us to remember you. We use this information in order to improve
					and
					customize your browsing experience and for analytics and metrics about our visitors both on this
					website
					and other media. To find out more about the cookies we use, see our <a
						href="https://m360.com.ph/data-privacy-policy/" target="_blank">Privacy Policy</a></p>
			</div>
			<p class="p" id="cookie-disclaimer">If you decline, your information won’t be tracked when you visit this
				website. A
				single cookie will be used in your browser to remember your preference not to be tracked.</p>
			<div id="hs-en-cookie-confirmation-buttons-area">
				<!--  -->
				<div id="cookie-confirmation-button-group">
					<b-button class="btn" @click="check" variant="secondary">Accept All</b-button>
					<b-button class="btn"  @click="check" variant='outline-secondary'>Decline All</b-button>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'cookie',
		data: () => {
			return {
				show: true
			}
		},

		methods: {
			check() {
				if (this.show) {
					this.show = false

				}
			}
		}
	}
</script>
<style lang="scss">
	@media screen and (max-width:480px) {
		#cookie-confirmation-inner {
			padding: 8px 14px 14px !important;
		}
		.cookieWrap {
			font-size:12px
			
		}
		

	}

	@media screen and (max-width:800px) {
		.cookieWrap .p {

			margin: 0 20px 12px !important;
		}
	}

	.cookieWrap {
		position: fixed;
		border-bottom: 0;
		bottom: 0;
		top: auto;
		left: 0;
		right: 0;
		box-shadow: 0 -1px 3px #eaf0f6;
		background: #fff;


		#cookie-confirmation-inner {

			background: #fff;
			margin: 0 auto;
			max-width: 1200px;
			padding: 20px;

		}

		.btn {
			margin: 6px;
		}

		.p {
			margin: 0 20px 12px;
			color: #33475b;
			font-family: inherit;
			font-size: inherit;
			font-weight: 400 !important;
			line-height: inherit;
			text-align: left;
			text-shadow: none !important;
		}
	}

	#policy-wording {

		margin-bottom: 12px;


	}

	#hs-en-cookie-confirmation-buttons-area {
		margin-right: 72px;
		justify-content: flex-end;
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	#cookie-confirmation-button-group {}


	// .cookieWrap {

	// 	position: fixed;
	// 	border-bottom: 0;
	// 	bottom: 0;
	// 	top: auto;
	// 	box-shadow: 0 -1px 3px #eaf0f6;
	// 	background: #fff;


	// }

	
</style>
