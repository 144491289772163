import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/icons/index'


import {BootstrapVue,IconsPlugin} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.scss'

Vue.use(BootstrapVue)

Vue.use(IconsPlugin)

Vue.config.productionTip = false


import SvgIcon from '/src/components/SvgIcon'

// 将SvgIcon组件注册到全局
Vue.component('svg-icon', SvgIcon)

// requires and returns all modules that match

// 遍历这个目录下的所有文件
const requireAll = requireContext => requireContext.keys().forEach(requireContext)

// import svg
// 指定目录和遍历的规则
const req = require.context('@/assets/icons/svg', true, /\.svg$/)

requireAll(req)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
