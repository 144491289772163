<template>
	<div class="home">

		<Header></Header>
		<div class="">
			<!-- home-banner -->
			<section class="home-banner">
				<div class="container">
					<article class="home-text">
						<h1 class="home-title">Your Messages Are in Good Hands</h1>
						<p translate="HOME.banner-sub-title-first" class="home-title-child">
							qqloon Global SMS and Voice, direct connections to telecom carriers, global reach to more
							than
							200 countries</p>
						<p translate="HOME.banner-sub-title-second" class="home-title home-title-child"></p><button
							translate="HOME.banner-button" class="home-button mt-46">Sign up</button>
						<p class="home-space"><span translate="HOME.banner-link">Already have an account? </span><a
								target="_blank" translate="HOME.banner-login"
								class="text-underline text-black-light cur-pointer" href="#">Sign in</a></p>
					</article>
					<picture>

						<img style="display: block;-webkit-user-select: none;margin: auto;cursor: zoom-out;background-color: hsl(0, 0%, 90%);"
							src="../../static/image/home-b1.gif" width="100%" height="auto">
					</picture>
				</div>
			</section>
			<!-- home-banner -->

			<!-- home-products -->
			<section class="home-products">
				<div class="container">
					<p translate="HOME.products-title" class="section-title">OUR PRODUCTS
					</p>
					<div class="section-title-line"></div>
					<p translate="HOME.products-subTitle" class="section-subTitle">We Offer
						High Quality Global Cloud Communication Services.</p>
					<ul class="home-products-list">
						<li class="home-products-item">
							<div class="home-products-item-link" tabindex="0">
								<!-- <svg class="icon home-products-item-icon">
									<use xlink:href="#icon-sms"></use>
								</svg> -->
								<svg-icon name="icon-plane" class="home-products-item-icon"></svg-icon>

								<p class="home-products-item-title">SMS API</p>
							</div>
							<p class="text-black-light">Global reach to 200 countries, high
								delivery rate, real time response</p>
						</li>
						<li class="home-products-item">
							<div class="home-products-item-link" tabindex="0">
								<svg-icon name="icon-mms" class="home-products-item-icon"></svg-icon>
								<p class="home-products-item-title">MMS API</p>
							</div>
							<p class="text-black-light">Move beyond simple text
								messages.qqloon’s MMS API enables you to send engaging messages using multimedia
								content.</p>
						</li>
						<li class="home-products-item">
							<div class="home-products-item-link" tabindex="0">
								<svg-icon name="icon-voice" class="home-products-item-icon"></svg-icon>
								<p class="home-products-item-title">Voice API</p>
							</div>
							<p class="text-black-light">Global reach to more than 200
								countries, an excellent failover solution to increase the user acquisition rate</p>
						</li>
						<li class="home-products-item">
							<div class="home-products-item-link" tabindex="0">
								<svg-icon name="icon-code" class="home-products-item-icon"></svg-icon>
								<p class="home-products-item-title">Phone Number Lookup API
								</p>
							</div>
							<p class="text-black-light">A free basic version offered to our
								customers, helping them to save costs by filtering invalid phone numbers. A premium
								version is available for real time mobile number porting (MNP) status lookup</p>
						</li>
						<li class="home-products-item">
							<div class="home-products-item-link" tabindex="0">
								<svg-icon name="icon-tools" class="home-products-item-icon"></svg-icon>
								<p class="home-products-item-title">SMS Sending Tool</p>
							</div>
							<p class="text-black-light">An intuitive application integrated
								into the customer dashboard, helping our customers to send bulk SMS without any
								technical development</p>
						</li>
						<!---->
					</ul>
				</div>
			</section>
			<!-- home-products -->

			<!-- home-strengths -->
			<section class="home-strengths">
				<picture>
					<source loading="lazy" type="image/webp" srcset="../../static/image/earth.png"><img alt="background"
						title="earth" width="1080" height="1080" loading="lazy" class="home-strengths-bg"
						src="../../static/image/earth.png">
				</picture>
				<div class="container">
					<p translate="HOME.strengths-title" class="section-title">OUR STRENGTHS
					</p>
					<div class="section-title-line"></div>


					<p translate="HOME.strengths-subTitle" class="section-subTitle text-white">qqloon is Your Best
						Choice for Global Messaging.</p>
					<ul class="home-strengths-list">
						<li class="home-strengths-item">
							<svg-icon name="icon-edit2" class="home-strengths-item-icon"></svg-icon>
							<p class="home-strengths-item-title">Scalable and Customizable
								Services</p>
							<ul class="home-strengths-item-details">
								<li class="details-li">Our smart algorithm automatically selects the most
									reliable route to carry your SMS traffic.</li>
								<li class="details-li">You can also ask us to build a customized routing plan
									to meet your specific requirements.</li>
							</ul>
						</li>
						<li class="home-strengths-item">
							<svg-icon name="icon-code2" class="home-strengths-item-icon"></svg-icon>
							<p class="home-strengths-item-title">Comprehensive API Services
							</p>
							<ul class="home-strengths-item-details">
								<li class="details-li">We help you to filter invalid phone numbers with the
									Phone Number Lookup API</li>
								<li class="details-li">You can check in real time the current network and
									roaming status of a phone number, with the premium version of our Phone Number
									Lookup API.</li>
							</ul>
						</li>
						<li class="home-strengths-item">
							<svg-icon name="icon-hotelsafe" class="home-strengths-item-icon"></svg-icon>
							<p class="home-strengths-item-title">Easy and Secure Integration
							</p>
							<ul class="home-strengths-item-details">
								<li class="details-li">qqloon platform supports both HTTP and SMPP protocols.
								</li>
								<li class="details-li">All the sensible information are encrypted.</li>
							</ul>
						</li>
						<li class="home-strengths-item">
							<svg-icon name="icon-message2" class="home-strengths-item-icon"></svg-icon>
							<p class="home-strengths-item-title">Responsive Customer Service
							</p>
							<ul class="home-strengths-item-details">
								<li class="details-li">We offer 24/7 technical support and customer service.
								</li>
								<li class="details-li">We also provide customized technical consulting services
									to meet your specific needs.</li>
							</ul>
						</li>
						<!---->
					</ul>
				</div>
			</section>

			<!-- home-strengths -->
			<!-- home-partners -->
			<section class="home-partners">
				<div class="container">
					<p translate="HOME.partners-title" class="section-title">OUR PARTNERS
					</p>
					<div class="section-title-line"></div>
					<ul class="home-partners-list">

						<li class="home-partners-item" v-for="(item,index) in arr" :key="index">
							<picture class="home-partners-item-wrap">
								<source loading="lazy" type="image/webp" srcset="../../static/image/DEMO-LOGO.png">
								<img width="100%" height="auto" loading="lazy" class="sponsors-img"
									src="../../static/image/DEMO-LOGO.png" alt="PatPat" title="PatPat">
							</picture>
							<!---->
						</li>
					</ul>
				</div>

			</section>
			<!-- home-partners -->

			<!-- home-pricing -->
			<section class="home-pricing">
				<div class="container">
					<p translate="HOME.partners-title" class="section-title">Discover our plans
					</p>
					<div class="section-title-line"></div>

					<div class="card-plan">
						<div class="flex justify-center wrap">
							<div class="card-wrapper">
								<div class=" card-badge  sms-diy starter">
									<div class="v-card__text">
										<p class="text-center">
											For
											Startup
										</p>
									</div>
								</div>
								<div class="elevation-0 v-card v-sheet theme--light" style="max-width: 373px;">
									<div class="v-card__title">
										<div>
											<p class="sub_title">Starting from</p>
											<h3>₱599 / Month</h3>
										</div>
									</div>
									<hr role="separator" aria-orientation="horizontal"
										class="card-divider v-divider theme--light" style="margin: 0px 34px;">

									<div class="v-card__text pricing-card-text card-bubble-bg starter"
										style="background-image: url(&quot;data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQxIiBoZWlnaHQ9IjM5OSIgdmlld0JveD0iMCAwIDM0MSAzOTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03ODQuOTc0IDUzNkw3Ni4xNTQyIDUzNkMzNC4zNzM2IDUzNiAwLjUgNTAxLjcxNiAwLjUgNDU5LjQyOVY2Ni44MDkyVjE0LjAzNzdDMC41IDEuODU5NjggMTQuODAxMiAtNC41MTkzNSAyMy42OTM2IDMuNjkyMTRMODAuMDUwMyA1NS44ODM4Qzg3LjYzNjQgNjIuOTEyMiA5Ny41MzcyIDY2Ljc4NiAxMDcuODA1IDY2Ljc4Nkg4MzIuMDQ5Qzg3My4wMDUgNjYuNzg2IDkwNi4xOTEgMTAwLjM3NCA5MDYuMTkxIDE0MS44MjZMOTA3LjUgNDEzLjMxNUM5MDcuNDc3IDQ4MS4wNzEgODUxLjg5NiA1MzYgNzg0Ljk3NCA1MzZaIiBmaWxsPSIjNDZCM0ZGIi8+Cjwvc3ZnPg==&quot;);">
										<div class="	 flex justify-end">
											<div class="text-right sub-text" style="max-width: 230px;">
												<div class="h5">Get up to 1,100 consumable 
												</div>

											</div>
										</div>
										<div class="feature">
											<p class="feature_item">FEATURES</p>
											<ul>
												<li>
													1,100 Sending Credits
												</li>
												<li>
													5,900 Receiving Credits
												</li>
												
												<li>
													₱0.52 per Additional Sending SMS
												</li>
												<li>
													₱0.20 per Additional Receiving SMS
												</li>
												<li>
													₱900 Credit Limit
												</li>
												<li>
													Add-ons can be applied
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="card-wrapper">
								<div class=" card-badge  sms-diy ">
									<div class="v-card__text">
										<p class="text-center">
											For
											Startup
										</p>
									</div>
								</div>
								<div class="elevation-0 v-card v-sheet theme--light" style="max-width: 373px;">
									<div class="v-card__title">
										<div>
											<p class="sub_title">Starting from</p>
											<h3>₱599 / Month</h3>
										</div>
									</div>
									<hr role="separator" aria-orientation="horizontal"
										class="card-divider v-divider theme--light" style="margin: 0px 34px;">

									<div class="v-card__text pricing-card-text card-bubble-bg "
										style="background-image: url(&quot;data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQxIiBoZWlnaHQ9IjM5OSIgdmlld0JveD0iMCAwIDM0MSAzOTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03ODQuOTc0IDUyOEw3Ni4xNTQyIDUyOEMzNC4zNzM2IDUyOCAwLjUgNDk0LjIyOCAwLjUgNDUyLjU3MlY2NS44MTJWMTMuODI4MkMwLjUgMS44MzE5MSAxNC44MDEyIC00LjQ1MTkgMjMuNjkzNiAzLjYzNzAyTDgwLjA1MDMgNTUuMDQ5N0M4Ny42MzY0IDYxLjk3MzIgOTcuNTM3MiA2NS43ODkyIDEwNy44MDUgNjUuNzg5Mkg4MzIuMDQ5Qzg3My4wMDUgNjUuNzg5MiA5MDYuMTkxIDk4Ljg3NjEgOTA2LjE5MSAxMzkuNzA5TDkwNy41IDQwNy4xNDZDOTA3LjQ3NyA0NzMuODkxIDg1MS44OTYgNTI4IDc4NC45NzQgNTI4WiIgZmlsbD0iIzAwOERGMCIvPgo8L3N2Zz4K&quot;);">



										<div class="	 flex justify-end">
											<div class="text-right sub-text" style="max-width: 230px;">
												<div class="h5">Get up to 1,100 consumable 
												</div>

											</div>
										</div>
										<div class="feature">
											<p class="feature_item">FEATURES</p>
											<ul>
												<li>
													1,100 Sending Credits
												</li>
												<li>
													5,900 Receiving Credits
												</li>
												
												<li>
													₱0.52 per Additional Sending SMS
												</li>
												<li>
													₱0.20 per Additional Receiving SMS
												</li>
												<li>
													₱900 Credit Limit
												</li>
												<li>
													Add-ons can be applied
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="card-wrapper">
								<div class=" card-badge  sms-diy ">
									<div class="v-card__text">
										<p class="text-center">
											For
											Startup
										</p>
									</div>
								</div>
								<div class="elevation-0 v-card v-sheet theme--light" style="max-width: 373px;">
									<div class="v-card__title">
										<div>
											<p class="sub_title">Starting from</p>
											<h3>₱599 / Month</h3>
										</div>
									</div>
									<hr role="separator" aria-orientation="horizontal"
										class="card-divider v-divider theme--light" style="margin: 0px 34px;">

									<div class="v-card__text pricing-card-text card-bubble-bg "
										style="background-image: url(&quot;data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQxIiBoZWlnaHQ9IjM5OSIgdmlld0JveD0iMCAwIDM0MSAzOTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03ODQuNDc0IDUyOEw3NS42NTQyIDUyOEMzMy44NzM2IDUyOCAwIDQ5NC4yMjggMCA0NTIuNTcyVjY1LjgxMlYxMy44MjgyQzAgMS44MzE5MSAxNC4zMDEyIC00LjQ1MTkgMjMuMTkzNiAzLjYzNzAyTDc5LjU1MDMgNTUuMDQ5N0M4Ny4xMzY0IDYxLjk3MzIgOTcuMDM3MiA2NS43ODkyIDEwNy4zMDUgNjUuNzg5Mkg4MzEuNTQ5Qzg3Mi41MDUgNjUuNzg5MiA5MDUuNjkxIDk4Ljg3NjEgOTA1LjY5MSAxMzkuNzA5TDkwNyA0MDcuMTQ2QzkwNi45NzcgNDczLjg5MSA4NTEuMzk2IDUyOCA3ODQuNDc0IDUyOFoiIGZpbGw9IiMwMDYwQTQiLz4KPC9zdmc+Cg==&quot;);">



										<div class="	 flex justify-end">
											<div class="text-right sub-text" style="max-width: 230px;">
												<div class="h5">Get up to 1,100 consumable 
												</div>

											</div>
										</div>
										<div class="feature">
											<p class="feature_item">FEATURES</p>
											<ul>
												<li>
													1,100 Sending Credits
												</li>
												<li>
													5,900 Receiving Credits
												</li>
												
												<li>
													₱0.52 per Additional Sending SMS
												</li>
												<li>
													₱0.20 per Additional Receiving SMS
												</li>
												<li>
													₱900 Credit Limit
												</li>
												<li>
													Add-ons can be applied
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>



			</section>

			<!-- home-pricing -->

			<!-- home-footer -->

			<Footer></Footer>

			<!-- home-footer -->
			<!-- cookie -->
			<cookie></cookie>
			<!-- cookie -->

		</div>

	</div>
</template>

<script>
	import Header from '/src/components/header.vue'
	import Footer from '/src/components/footer.vue'
	import cookie from '/src/components/cookie.vue'

	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			cookie
		},
		data: () => {
			return {


				arr: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				brr: [1,2,3],
				options: {
					distanceBetweenElements: '5px', // px
					positionNav: 'center' // left / right / center
				}
			}
		},
		mounted() {

		},
		methods: {


		}
	}
</script>
