<template>
	<div class="headerWrap">
		<b-navbar id="none" toggleable="lg" type="light" variant="">

			<b-navbar-brand href="#">

				<!-- 		<b-img class="header-logo-img" blank blank-color="#ccc" width="168" height="35" alt="placeholder">
				</b-img> -->
				<img src="../../static/image/logo.png" class="header-logo-img" alt="placeholder">

			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<!-- <b-navbar-nav>
							<b-nav-item href="#">Link</b-nav-item>
							<b-nav-item href="#" disabled>Disabled</b-nav-item>
						</b-navbar-nav> -->

				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto">
					<!-- 						<b-nav-form>
								<b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
								<b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
							</b-nav-form> -->
					<b-nav-item href="#detail">Home</b-nav-item>
					<!-- <b-nav-item href="#skill">Products</b-nav-item> -->
					<b-nav-item-dropdown text="Products" right>
						<b-dropdown-item href="#">EN</b-dropdown-item>
						<b-dropdown-item href="#">ES</b-dropdown-item>
						<b-dropdown-item href="#">RU</b-dropdown-item>
						<b-dropdown-item href="#">FA</b-dropdown-item>
					</b-nav-item-dropdown>
					<b-nav-item href="#education">Pricing</b-nav-item>
					<b-nav-item href="#">Products</b-nav-item>



				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<a href="#top">
			<div class="floating-icon floating-icon-up">
				<!-- <svg class="icon">
					<use xlink:href="#icon-up"></use>
				</svg> -->
				<svg-icon name="icon-arrow-top" class="icon-arrow-top flex-center"></svg-icon>

			</div>
		</a>

	</div>

</template>

<script>
	export default {
		name: "Header",
		
	}
</script>

<style>
	.headerWrap {
		position: sticky;
		top: 0;
		z-index: 1020;
		background-color: #fff;
		
	}

	.floating-icon-up {
		bottom: 15px;
	}

	.floating-icon {
		position: fixed;
		right: 20px;
		background: #fff;
		border: 1px solid #EBEBEB;
		border-radius: 4px;
		padding: 7.63px;
		z-index: 20010;
		cursor: pointer;

	}


	.container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}

	.header-logo-img {
		width: 168px;
		position: relative;
		z-index: 1001;
		max-height: 35px;
	}

	.icon-arrow-top {

		height: 1em;
		width: 1em;
	}

	@media (min-width: 768px) {
		.container {
			/* width: 750px; */
		}
	}

	@media (min-width: 992px) {
		.container {
			/* width: 970px; */
		}
	}

	@media (min-width: 1200px) {
		.container {
			/* width: 1170px; */
		}
	}
</style>
