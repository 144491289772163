<template>
  <div>
    <svg class="icon">
      <use :xlink:href="'#'+name"></use>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['name'],
  name: 'SvgIcon'
}
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
}
</style>